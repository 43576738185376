<template>
    <layout-default>
        <section class="p-0">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <b-carousel
                            id="carousel-2"
                            :interval="6000"
                            fade
                        >
                            <b-carousel-slide :img-src="selectedImage1"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage2"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage1"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage2"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage1"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage2"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage1"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage2"></b-carousel-slide>
                        </b-carousel>
                    </div>
                    <div class="col-lg-5">
                        <div class="row">
                            <div class="col-lg-12 text-center" :class="{ 'bg-primary text-white': selectedColumn === 'map' }"  @mouseover="changeImage('map')">
                                <div class="p-5 my-lg-5"><i class="fas fa-3x mb-4 fa-map-marked-alt text-primary" :class="{ 'text-white': selectedColumn === 'map' }"></i>
                                    <h3 class="h5 text-uppercase mb-4">Standort</h3>
                                    <p>
                                        <a :class="{ 'text-white': selectedColumn === 'map' }" href="https://www.google.com/maps/place/Heinrich-Brandt-Straße+25,+91575+Windsbach" target="_blank" rel="noopener noreferrer">
                                            Heinrich-Brandt-Straße 25,<br>
                                            91575 Windsbach
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center" :class="{ 'bg-primary text-white': selectedColumn === 'telefon' }" @mouseover="changeImage('telefon')">
                                <div class="p-5 my-lg-5"><i class="fas fa-3x mb-4 fa-first-aid text-primary" :class="{ 'text-white': selectedColumn === 'telefon' }"></i>
                                    <h3 class="h5 text-uppercase mb-4">Telefon</h3>
                                    <p>
                                        <a :class="{ 'text-white': selectedColumn === 'telefon' }" v-bind:href="`tel:${$t('frame.phone')}`">{{ $t('frame.phone') }}</a>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center" :class="{ 'bg-primary text-white': selectedColumn === 'email' }" @mouseover="changeImage('email')">
                                <div class="p-5 my-lg-5"><i class="fas fa-3x mb-4 fa-envelope text-primary" :class="{ 'text-white': selectedColumn === 'email' }"></i>
                                    <h3 class="h5 text-uppercase mb-4">Email</h3>
                                    <p>
                                        <a :class="{ 'text-white': selectedColumn === 'email' }" v-bind:href="`mailto:${$t('frame.email')}`">{{ $t('frame.email') }}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="services bg-light" id="termin">
            <div class="container">
                <div class="text-center">
                    <h2 class="text-uppercase lined lined-center">Jetzt <span class="text-primary">Termin</span> vereinbaren</h2>
                    <p class="text-muted mb-5">Melden Sie sich einfach per Telefon oder E-Mail.</p>
                </div>
                <div class="row gy-4">
                    <div class="col-xl-6 col-md-6">
                        <div class="card border-0 h-100">
                            <div class="card-body border-top border-2 border-primary py-5 px-4 shadow-sm d-flex flex-column justify-content-center align-items-center">
                                <ul class="list-unstyled m-0">
                                    <li class="d-flex align-items-center pb-0">
                                        <div class="p-1 bg-primary text-white rounded-circle">
                                        <div class="p-4 border border-2 border-white rounded-circle" style="border-width: 2px !important;"><i class="fas fa-2x fa-phone"></i></div>
                                        </div>
                                        <div class="ms-3">
                                        <h3 class="h5"><a v-bind:href="`tel:${$t('frame.phone')}`">{{ $t('frame.phone') }}</a></h3>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6">
                        <div class="card border-0 h-100">
                            <div class="card-body border-top border-2 border-primary py-5 px-4 shadow-sm d-flex flex-column justify-content-center align-items-center">
                                <ul class="list-unstyled m-0">
                                    <li class="d-flex align-items-center pb-0">
                                        <div class="p-1 bg-primary text-white rounded-circle">
                                        <div class="p-4 border border-white rounded-circle border-2" style="border-width: 2px !important;"><i class="fas fa-2x fa-envelope"></i></div>
                                        </div>
                                        <div class="ms-3">
                                            <h3 class="h5 text-break"><a v-bind:href="`mailto:${$t('frame.email')}`">{{ $t('frame.email') }}</a></h3>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </layout-default>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';
import houseOutside from '../assets/img/haus.jpg';
import carolinTel from '../assets/img/carolin_tel.jpg';
import helenaTel from '../assets/img/heltel.jpg';
import annePC from '../assets/img/anne_pc.jpg';
import stefaniePC from '../assets/img/stefanie_pc.jpg';

export default {
    name: 'Kontakt',
    components: {
        LayoutDefault
    },
    data() {
        return {
            selectedImage1: carolinTel,
            selectedImage2: helenaTel, // Standardbildpfad
            selectedColumn: 'telefon',
            slide: 0,
            sliding: null
        };
    },
    methods: {
        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = true;
        },
        changeImage(selectedColumn) {
            switch (selectedColumn) {
            case 'map':
                this.selectedImage1 = houseOutside;
                this.selectedImage2 = houseOutside;
                this.selectedColumn = 'map';
                break;
            case 'telefon':
                this.selectedImage1 = carolinTel;
                this.selectedImage2 = helenaTel;
                this.selectedColumn = 'telefon';
                break;
            case 'email':
                this.selectedImage1 = stefaniePC;
                this.selectedImage2 = annePC;
                this.selectedColumn = 'email';
                break;
            default:
                this.selectedImage1 = carolinTel;
                this.selectedImage2 = helenaTel;
                this.selectedColumn = 'telefon';
                break;
            }
        }
    }
};
</script>
